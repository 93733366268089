<template>
    <div id="recharge">
        <div class="admin_main_block">
            <div class="user_temp_title">账户充值</div>
            <div class="admin_table_main">
                <div class="recharge_main">
                    <div class="user_money">
                        <b>可用资金：</b>
                        <font color="#FF6C00" style="font-size: 22px;">{{$store.state.userInfo.info.balance}}元</font>
                    </div>
                    <div class="chose_pay_type">
                        <div class="pay_type">
                            <!-- <div @click="payWay = 3">微信支付
                                <img v-if="payWay == 3" src="@/assets/img/09.png" alt="">
                            </div> -->
                            <!-- <div @click="payWay = 1">
                                支付宝支付
                                <img v-if="payWay == 1" src="@/assets/img/09.png" alt="">
                            </div> -->
                             <div @click="payWay = 2">支付宝支付
                                <img v-if="payWay == 2" src="@/assets/img/09.png" alt="">
                            </div>
                            
                        </div>
                    </div>
                    <div class="recharge_num">
                        <label>
                            充  值  金  额
                        </label>
                        <!-- <input type="text" style="font-size: 20px;" @keyup="clearNoNum" v-model="amount">元 -->
                        <!-- <div class="hint" v-if="$store.state.tenantInfo.id==1">*单笔充值1000即可自动升级高级会员</div> -->
                        <div class="list">
                            <div @click="active=1" :class="['item',active==1?'active':'']">￥50</div>
                            <div @click="active=2" :class="['item',active==2?'active':'']">￥200</div>
                            <div @click="active=6" :class="['item',active==6?'active':'']">￥500 </div>
                            <div @click="active=3" :class="['item',active==3?'active':'']">￥1000</div>
                            <!-- <div @click="active=3" :class="['item',active==3?'active':'']">￥1000 <span v-if="$store.state.tenantInfo.id==1">赠送VIP</span></div> -->
                            <div @click="active=4" :class="['item',active==4?'active':'']">￥3000</div>
                            <div @click="active=5,amount = ''" :class="['item text',active==5?'active':'']">
                                <input type="text" v-model="amount" @keyup="clearNoNum" placeholder="其他金额">
                            </div>
                            <div class="btn" @click="transfer">确认转账</div>
                        </div>
                    </div>
                    <div class="chose_pay_type">
                        <!-- <div>扫描下方二维码完成充值（支付完请刷新页面）</div> -->
                        <div>扫描下方二维码完成充值,<span v-if="$store.state.tenantInfo.id==1">最低充值50元，</span>最高充值3000元，可反复多次充值（支付完请刷新页面）</div>
                    <div class="recharge_img" v-if="payWay!=2">
                        <img v-if="qr" src="@/assets/img/recharge-06.ffabee2.png" alt="">
                        <img v-else :src="payQrcode" alt="">
                    </div>
                        <!-- <div class="tip">
                            <font style="font-size: 14px;">
                                <b>充值指南：</b>
                            </font><br>
                            1.先去支付宝扫码支付或转账打款到&nbsp;&nbsp;
                            <b style="color: rgb(51, 51, 51); font-size: 14px;">caiwu@daifatu.com</b>
                            &nbsp;天台****有限公司 (当打款不成功时核对一下支付宝账户)。<br>
                            3.打款成功后，请过3分钟后再提交，超过12小时提交无效。<br>
                            4.如遇到充值未成功，超过5分钟还提示订单号不存在，请联系客服处理！<br>
                            5.平台充值永久免手续费！<br>
                        </div> -->
                        <!-- <div class="recharge_num">
                            <img src="@/assets/img/alipay_pic.png" alt="" width="890px">
                        </div> -->
                    </div>
                </div>
            </div>
        </div>
        <div v-html="aliPayForm">
			{{aliPayForm}}
		</div>
    </div>
</template>

<script>
import QRCode from 'qrcode'

export default {
    name: '',
    data() {
        return {
            payWay: 2,//1支付宝扫码支付，2微信扫码支付，3支付宝电脑网站支付
            amount:'',//充值金额
            loading:false,//防重复点击
            aliPayForm:'',
            qr:true,
            payQrcode:'',
            active:1,
            aboutShow:1,
        }
    },
    created(){
        if("www.fabwang.com;fab.fabwang.com".indexOf(window.location.host)!=-1){
            this.aboutShow = 2;
        }
    },
    mounted(){},
    watch: {},
    methods: {
        //确认转账
        transfer(){
            if(this.loading) return;
            this.loading = true;
            if(this.active==5){
                if(!this.amount){
                    this.$message.error("充值金额不能为空");
                    this.loading = false;
                    return;
                }
                if(this.amount<50){
                    if(this.$store.state.tenantInfo.id==1){
                        this.$message.error("充值金额不能小于50");
                        this.loading = false;
                        return;
                    }
                }
            }
            var amount = 0;
            if(this.active==1){
                amount = 50;
            }else if(this.active==2){
                amount = 200;
            }else if(this.active==3){
                amount = 1000;
            }else if(this.active==4){
                amount = 3000;
            }else if(this.active==6){
                amount = 500;
            }else{
                amount = this.amount;
            }
            if(this.payWay==1){
                this.aliPay(amount);
            }else if(this.payWay==2){
                this.aliPay2(amount);
            }else if(this.payWay==3){
                if(this.active==5){
                    if(this.amount>3000){
                        this.$message.error("充值金额不能大于3000");
                        this.loading = false;
                        return;
                    }
                }
                this.wxPay(amount);
            }
        },
        //支付宝当面付，返回链接
        aliPay(amount){
            const loading = this.$loading();
            this.$request.post({
                url:'/alipay/bus/dmfRecharge',
                params:{
                    money:amount,
                },
                success: res => {
                    this.qrcode(res);
                },
                finally: () => {
                    this.loading = false;
                    loading.close();
                }
            })
        },
        
        //支付宝电脑网站支付
        aliPay2(amount){
            const loading = this.$loading();
            this.$request.post({
                url:'/alipay/bus/recharge',
                params:{
                    money:amount,
                },
                success: res => {
                    this.aliPayForm = res
                    this.$nextTick(()=>{
                        document.forms[0].submit()
                    })
                },
                finally: () => {
                    this.loading = false;
                    loading.close();
                }
            })
        },
        //微信支付
        wxPay(amount){
            const loading = this.$loading();
            this.$request.post({
                url:'/wechat/pay/bus/qrPay',
                params:{
                    money:amount,
                },
                success: res => {
                    this.qrcode(res);
                },
                finally: () => {
                    this.loading = false;
                    loading.close();
                }
            })
        },
        //验证金额
        clearNoNum(){
			var _this = this;
            _this.amount = _this.amount.replace(/[^\d.]/g,""); //清除"数字"和"."以外的字符  
            _this.amount = _this.amount.replace(/^\./g,""); //验证第一个字符是数字而不是  
            _this.amount = _this.amount.replace(/\.{2,}/g,"."); //只保留第一个. 清除多余的  
            _this.amount = _this.amount.replace(".","$#$").replace(/\./g,"").replace("$#$",".");  
            _this.amount = _this.amount.replace(/^(\-)*(\d+)\.(\d\d).*$/,'$1$2.$3'); //只能输入两个小数
			if(_this.amount < 1){
				_this.amount = '';
			}
        },
        qrcode(text){
	      	QRCode.toDataURL(text)
			.then(url => {
				this.payQrcode = url;
			})
			.catch(err => {
				//consloe.error(err)
			})
	        this.qr=false;
	    },

    }
}
</script>

<style lang="scss" scoped>
#recharge{
    .admin_main_block{
        min-height: 855px;
        background: #fff;
        width: 100%;
        box-sizing: border-box;
        padding: 20px;
        border-radius: 4px;
        .hint{
            line-height: 20px;
            font-size: 14px;
            color: #f80;
            margin-top: 10px;
        }
        .user_temp_title{
            margin: auto;
            padding-left: 20px;
            border-bottom: 1px solid #eeeeee;
            padding-bottom: 20px;
            height: 25px;
            margin-bottom: 20px;
            font-weight: bold;
        }
        .recharge_main{
            padding: 0 30px;
            font-size: 12px;
            .user_money{
                font-size: 14px;
                line-height: 45px;
            }
            .chose_pay_type{
                margin-top: 30px;
                .pay_type{
                    div{
                        position: relative;
                        width: 100px;
                        text-align: center;
                        display: inline-block;
                        font-size: 16px;
                        padding: 10px 15px;
                        border: 1px solid #F80;
                        border-radius: 4px;
                        margin-right: 5px;
                        cursor: pointer;
                    }
                    img{
                        position: absolute;
                        bottom: 0;
                        right: 0;
                        width: 20px;
                    }
                }
                .btn{
                    margin-left: 137px;
                    margin-top: 20px;
                    width: 292px;
                    border: 0px;
                    background-color: #f80;
                    text-align: center;
                    line-height: 40px;
                    border-radius: 4px;
                    color: #FFFFFF;
                    font-size: 16px;
                    cursor: pointer;
                }
            }
            .recharge_num{
                font-size: 18px;
                margin-top: 30px;
                .list{
                    .item{
                        position: relative;
                        width: 100px;
                        text-align: center;
                        display: inline-block;
                        font-size: 16px;
                        padding: 10px 15px;
                        border: 1px solid #ccc;
                        color: #ccc;
                        border-radius: 4px;
                        margin-right: 5px;
                        cursor: pointer;
                        &.text{
                            padding: 2px;
                            top: -2px;
                        }
                        span{
                            position: absolute;
                            top: 0;
                            right: 0;
                            color: red;
                            font-size: 12px;
                        }
                    }
                    .active{
                        border-color: #f80;
                        color: #f80;
                    }
                }
                .btn{
                    display: inline-block;
                    margin-left: 27px;
                    margin-top: 20px;
                    width: 100px;
                    border: 0px;
                    background-color: #f80;
                    text-align: center;
                    line-height: 43px;
                    border-radius: 4px;
                    color: #FFFFFF;
                    font-size: 16px;
                    cursor: pointer;
                }
                input{
                    width: 100px;
                    border: 0;
                    outline: none;
                    font-size:  16px;
                    text-align: center;
                    height: 37px;
                }
                label{
                    font-size:24px;
                }
            }
            .recharge_img{
                margin-left: 127px;
                margin-top: 50px;
                img{
                    width: 200px;
                }
            }
            .tip{
                color: #fd0004;
                background: #ffefe2;
                padding: 18px 22px;
                line-height: 30px;
                margin-top: 20px;
            }
        }

    }
}
</style>